import {
    AvatarContainer,
    AvatarImg,
    Background,
    BigCenteredVerticalLine,
    CenterDot,
    CenteredVerticalLine,
    ConfianceDiv,
    ContactDetails,
    Content,
    DescriptionText,
    DescriptionTextRight,
    Details,
    DivContact,
    DivContactLeft,
    ExpertiseDiv,
    FormContact,
    FormContactForm,
    FormInput,
    FormText,
    FormTextarea,
    HomeContainer,
    LayoutText,
    OrangeText,
    PhilosophieDiv,
    QualiteDiv,
    RightDiv,
    RightDivFirst,
    RightDivSecond,
    RomanNumber,
    SecureDreamsText,
    SendButton,
    TeamText,
    TitleContact,
    TitleUpercase,
    WhiteButton,
    WhiteText,
    YellowText
} from '../css/Home.style'
import Navbar from '../components/Navbar'
// @ts-ignore
import kyllian from "../../assets/kylian.png"
// @ts-ignore
import marius from "../../assets/marius.png"
// @ts-ignore
import yani from "../../assets/yani.png"
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import Footer from "../components/Footer";
import {Alert, IconButton} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {useIntl} from 'react-intl';

const Home = () => {
    const navigate = useNavigate()
    const loc = useLocation()
    const [formData, setFormData] = useState({name: '', email: '', message: ''});
    const [formStatus, setFormStatus] = useState("");
    const { formatMessage } = useIntl();

    const handleTeamClick = () => {
        navigate('/team/')
    };

    useEffect(() => {
        const hash = loc.hash;
        if (hash === '#contact') {
            const contactSection = document.getElementById('contact');
            if (contactSection) {
                contactSection.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, [loc]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:3001/send-email', formData);
            setFormStatus(response.data.message);
        } catch (error) {
            setFormStatus('Failed to send message. Please try again.');
        }
    };

    return (
        <HomeContainer>
            <Background/>
            <Navbar selectedNavItem="Intro"/>
            <Content style={{overflowX: "hidden"}}>
                <SecureDreamsText style={{textTransform: "uppercase"}}>{formatMessage({ id: 'home.title.1' })}<YellowText>.</YellowText></SecureDreamsText>
                <DescriptionText>
                    {formatMessage({ id: 'home.description.1' })} <OrangeText>{formatMessage({ id: 'home.description.2' })}</OrangeText> {formatMessage({ id: 'home.description.3' })}
                </DescriptionText>
                <WhiteButton onClick={() => {
                    window.location.replace("/#contact")
                }}>{formatMessage({ id: 'home.contact.button' })}<YellowText>!</YellowText></WhiteButton>
                <CenteredVerticalLine/>
                <RightDiv>
                    <RightDivFirst>
                        <TitleUpercase style={{textTransform: "uppercase"}}>{formatMessage({ id: 'home.question' })}<YellowText>?</YellowText></TitleUpercase>
                        <DescriptionTextRight>
                            {formatMessage({ id: 'home.description.right.1' })}
                            <OrangeText> {formatMessage({ id: 'home.description.right.2' })}</OrangeText> {formatMessage({ id: 'home.description.right.3' })}<OrangeText>{formatMessage({ id: 'home.description.right.4' })}</OrangeText>.
                        </DescriptionTextRight>
                    </RightDivFirst>
                    <RightDivSecond>
                        <AvatarContainer>
                            <AvatarImg src={marius} alt="Avatar"/>
                            <AvatarImg src={kyllian} alt="Avatar"/>
                            <AvatarImg src={yani} alt="Avatar"/>
                        </AvatarContainer>
                        <TeamText style={{cursor: "pointer"}} onClick={handleTeamClick}>{formatMessage({ id: 'home.team.right' })}</TeamText>
                        <ArrowOutwardIcon sx={{color: "rgba(218, 165, 32, 1)", cursor: "pointer"}}
                                          onClick={handleTeamClick}/>
                    </RightDivSecond>
                </RightDiv>
                <LayoutText style={{textTransform: "uppercase"}}>
                    {formatMessage({ id: 'home.title.2' })}<YellowText>?</YellowText>
                </LayoutText>
                <BigCenteredVerticalLine/>
                <QualiteDiv>
                    <TitleUpercase style={{
                        position: "absolute",
                        width: "80%",
                        left: "10%",
                        textTransform: "uppercase"
                    }}>{formatMessage({ id: 'home.quality.title' })}<YellowText>.</YellowText></TitleUpercase>
                    <WhiteText style={{position: "absolute", width: "80%", left: "10%", marginTop: "36px"}}>
                        {formatMessage({ id: 'home.quality.text.1' })}
                        <OrangeText>
                            {formatMessage({ id: 'home.quality.text.2' })}</OrangeText>.</WhiteText>
                </QualiteDiv>
                <RomanNumber align="right"
                             style={{position: "absolute", top: `calc(882px + 75px + 187px + 60px)`}}>I</RomanNumber>
                <CenterDot style={{top: `calc(882px + 75px - 29px + 187px + 60px )`}}>.</CenterDot>
                <ExpertiseDiv>
                    <TitleUpercase style={{
                        position: "absolute",
                        width: "80%",
                        left: "10%",
                        textTransform: "uppercase"
                    }}>{formatMessage({ id: 'home.expertise.title' })}<YellowText>.</YellowText></TitleUpercase>
                    <WhiteText style={{position: "absolute", width: "80%", left: "10%", marginTop: "36px"}}>
                        {formatMessage({ id: 'home.expertise.text.1' })} <OrangeText>{formatMessage({ id: 'home.expertise.text.2' })}</OrangeText>
                        {formatMessage({ id: 'home.expertise.text.3' })} <OrangeText>{formatMessage({ id: 'home.expertise.text.4' })}</OrangeText>
                        {formatMessage({ id: 'home.expertise.text.5' })}<OrangeText>{formatMessage({ id: 'home.expertise.text.6' })}</OrangeText>.</WhiteText>
                </ExpertiseDiv>
                <RomanNumber align="left"
                             style={{position: "absolute", top: `calc(1147px + 75px + 187px + 60px )`}}>II</RomanNumber>
                <CenterDot style={{top: `calc(1147px + 75px - 29px + 187px + 60px )`}}>.</CenterDot>
                <PhilosophieDiv>
                    <TitleUpercase style={{
                        position: "absolute",
                        width: "80%",
                        left: "10%",
                        textTransform: "uppercase"
                    }}>{formatMessage({ id: 'home.philosophy.title' })}<YellowText>.</YellowText></TitleUpercase>
                    <WhiteText style={{position: "absolute", width: "80%", left: "10%", marginTop: "36px"}}>
                        {formatMessage({ id: 'home.philosophy.text.1' })} <OrangeText>{formatMessage({ id: 'home.philosophy.text.2' })}</OrangeText>{formatMessage({ id: 'home.philosophy.text.3' })}</WhiteText>
                </PhilosophieDiv>
                <RomanNumber align="right" style={{
                    position: "absolute",
                    top: `calc(1442px + 75px + 187px + 60px )`
                }}>III</RomanNumber>
                <CenterDot style={{top: `calc(1442px + 75px - 29px + 187px + 60px )`}}>.</CenterDot>

                <ConfianceDiv>
                    <TitleUpercase style={{
                        position: "absolute",
                        width: "80%",
                        left: "10%",
                        textTransform: "uppercase"
                    }}>{formatMessage({ id: 'home.trust.title' })}<YellowText>.</YellowText></TitleUpercase>
                    <WhiteText style={{position: "absolute", width: "80%", left: "10%", marginTop: "36px"}}>
                        {formatMessage({ id: 'home.trust.text.1' })} <OrangeText>{formatMessage({ id: 'home.trust.text.2' })} </OrangeText>
                        {formatMessage({ id: 'home.trust.text.3' })} <OrangeText>{formatMessage({ id: 'home.trust.text.4' })} </OrangeText>
                        {formatMessage({ id: 'home.trust.text.5' })} </WhiteText>
                </ConfianceDiv>
                <RomanNumber align="left"
                             style={{position: "absolute", top: `calc(1707px + 75px + 187px + 60px )`}}>IV</RomanNumber>
                <CenterDot style={{top: `calc(1707px + 75px - 29px + 187px + 60px )`}}>.</CenterDot>
                <DivContact id="contact">
                    <DivContactLeft>
                        <TitleContact>{formatMessage({ id: 'home.contact.title' })}<YellowText>.</YellowText></TitleContact>
                        <ContactDetails>{formatMessage({ id: 'home.contact.text.1' })}<YellowText>.</YellowText></ContactDetails>
                        <Details>
                            <LocalPhoneIcon style={{
                                color: "rgba(218, 165, 32,1)",
                                top: "5px",
                                position: "relative",
                                marginRight: "16px"
                            }}/>07.
                            67. 46. 17. 31<br/>
                            <DriveFileRenameOutlineOutlinedIcon
                                style={{
                                    color: "rgba(218, 165, 32,1)",
                                    top: "5px",
                                    position: "relative",
                                    marginRight: "16px"
                                }}/>contact@secureflow.com
                        </Details>
                    </DivContactLeft>
                    <div style={{top: "2%", left: "50%", position: "absolute"}}>
                        <IconButton>
                            <InstagramIcon sx={{color: "rgba(255, 255, 255, 1)"}}/>
                        </IconButton>
                        <IconButton>
                            <LinkedInIcon sx={{color: "rgba(255, 255, 255, 1)"}}/>
                        </IconButton>
                        <IconButton>
                            <XIcon sx={{color: "rgba(255, 255, 255, 1)"}}/>
                        </IconButton>
                    </div>
                    <FormContact>
                        <FormContactForm onSubmit={handleSubmit}>
                            <FormText>{formatMessage({ id: 'home.contact.form.name' })}<OrangeText>*</OrangeText></FormText><br/>
                            <FormInput type="text" id="name" name="name" placeholder="Enter your name..."
                                       onChange={handleChange}/><br/>

                            <FormText>{formatMessage({ id: 'home.contact.form.email' })}<OrangeText>*</OrangeText></FormText><br/>
                            <FormInput type="text" id="email" name="email" placeholder="Enter your email address..."
                                       required onChange={handleChange}/><br/>

                            <FormText>{formatMessage({ id: 'home.contact.form.message' })}<OrangeText>*</OrangeText></FormText><br/>
                            <FormTextarea id="message" name="message" placeholder="Enter your message..."
                                          required onChange={handleChange}></FormTextarea><br/>

                            <SendButton type="submit">{formatMessage({ id: 'home.contact.form.button' })}</SendButton>
                            {formStatus === "Emails sent successfully" &&
                                <Alert sx={{top: "20px"}} severity="success">
                                    Email sent successfully
                                </Alert>
                            }
                            {formStatus === "Failed to send message. Please try again." &&
                                <Alert sx={{top: "30px"}} severity="error">
                                    {formStatus}
                                </Alert>
                            }
                        </FormContactForm>
                    </FormContact>
                </DivContact>
                <div style={{top: "2967px", position: "absolute", width: "100%"}}>
                    <Footer/>
                </div>
            </Content>
        </HomeContainer>
    );
};

export default Home;
