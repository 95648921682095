import styled from 'styled-components';

export const NavbarContainer = styled.div`
  position: fixed;  
  top: 48px;
  right: 48px;
  width: 410px;
  height: 56px;
  background: rgba(255, 255, 255, 1);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  padding-right: 8px;
  padding-left: 8px;
  z-index: 10;
  &.relative {
    position: relative; 
  }
`;

export const NavItem = styled.div<{ selected?: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: ${({ selected }) => (selected ? 'rgba(218, 165, 32, 1)' : 'rgba(23, 23, 23, 1)')};
  width: auto;
  height: 40px;
  padding-left: 24px;
  padding-right: 24px;
  line-height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  z-index: 1;

  &:hover {
    color: rgba(218, 165, 32, 1);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ selected }) => (selected ? 'rgba(23, 23, 23, 1)' : 'transparent')};
    border-radius: 40px;
    z-index: -1;
  }
`;

export const TextContainer = styled.div`
  position: fixed;  
  top: 48px; 
  left: 48px;
  z-index: 1000;
`;

export const SecureFlow = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: rgba(233, 234, 236, 1);
`;

export const Initiative = styled.div`
  font-family: 'Cormorant Garamond', serif;
  font-weight: 500;
  font-size: 24px;
  color: rgba(218, 165, 32, 1);
`;
