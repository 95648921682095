import React, {useContext, useState} from 'react';
import {
    AppBar,
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {Link, useLocation} from 'react-router-dom';
import {Initiative, SecureFlow, TextContainer} from "../css/NavbarMobile.style";
// @ts-ignore
import backgroundImage from '../../assets/backgroundHome.png';
import {useIntl} from "react-intl";
import LanguageIcon from "@mui/icons-material/Language";
import {LocaleContext} from "../../context/LocaleContext";

const NavbarMobile = () => {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const localeContext = useContext(LocaleContext);
    const { formatMessage } = useIntl();

    if (!localeContext) {
        throw new Error('LocaleContext must be used within a LocaleProvider');
    }

    const {locale, changeLocale} = localeContext;

    const toggleLocale = () => {
        console.log(locale)
        const newLocale = locale === 'en' ? 'fr' : 'en';
        changeLocale(newLocale);
        console.log(newLocale)
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const isActive = (path: string) => {
        return location.pathname === path;
    };

    return (
        <div>
            <AppBar position="fixed" sx={{backgroundColor: "transparent", boxShadow: "none"}}>
                <Toolbar>
                    <Box sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                        <TextContainer>
                            <SecureFlow>Secure Flow</SecureFlow>
                            <Initiative>I N I T I A T I V E</Initiative>
                        </TextContainer>
                    </Box>
                    <IconButton color="inherit" aria-label="menu" onClick={handleDrawerOpen} sx={{marginTop: "55px"}}>
                        <MenuIcon sx={{color: "white", width: "24px"}}/>
                    </IconButton>
                    <Tooltip title={formatMessage({id: 'navbar.international'})}>
                        <LanguageIcon
                            color={"primary"}
                            style={{
                                position: "fixed",
                                top: "64px",
                                right: "8px",
                                cursor: "pointer",
                                zIndex: 1
                            }}
                            onClick={toggleLocale}
                        />
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
                <Box
                    sx={{
                        width: '100vh',
                        height: '100vh',
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                    role="presentation"
                    onClick={handleDrawerClose}
                    onKeyDown={handleDrawerClose}
                >
                    <IconButton onClick={handleDrawerClose} sx={{color: "rgba(233, 234, 236, 1)"}}>
                        <CloseIcon sx={{width: "24px"}}/>
                    </IconButton>
                    <List>
                        {[formatMessage({id: 'navbar.intro'}), formatMessage({id: 'navbar.services'}), formatMessage({id: 'navbar.team'}), formatMessage({id: 'navbar.contact'})].map((text, index) => (
                            <ListItem
                                component={Link}
                                // Correctly setting paths for each section
                                to={
                                    text === "Intro"
                                        ? "/"
                                        : text === "Contact"
                                            ? "/#contact"
                                            : `/${text.toLowerCase()}`
                                }
                                key={text}
                                sx={{
                                    marginBottom: index !== 3 ? '32px' : '0',
                                    paddingLeft: '40px',
                                    marginTop: "40px",
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            sx={{
                                                fontFamily: 'Roboto',
                                                fontSize: '64px',
                                                fontWeight: 500,
                                                lineHeight: '75px',
                                                textAlign: 'left',
                                                color: "rgba(233, 234, 236, 1)",
                                                borderBottom: isActive(text === "Intro" ? "/" : `/${text.toLowerCase()}`)
                                                    ? '3px solid rgba(218, 165, 32, 1)'
                                                    : 'none',
                                                display: 'inline-block'
                                            }}
                                        >
                                            {text}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </div>
    )
}

export default NavbarMobile;
