import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {Initiative, NavbarContainer, NavItem, SecureFlow, TextContainer} from '../css/Navbar.style';
import {Box, Tooltip} from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import {LocaleContext} from "../../context/LocaleContext";
import {useIntl} from "react-intl";

const Navbar: React.FC<{ selectedNavItem: string }> = ({selectedNavItem}) => {
    const localeContext = useContext(LocaleContext);
    const { formatMessage } = useIntl();

    if (!localeContext) {
        throw new Error('LocaleContext must be used within a LocaleProvider');
    }

    const {locale, changeLocale} = localeContext;

    const toggleLocale = () => {
        console.log(locale)
        const newLocale = locale === 'en' ? 'fr' : 'en';
        changeLocale(newLocale);
        console.log(newLocale)
    };

    return (
        <>
            <TextContainer onClick={() => window.location.replace("/")} style={{cursor: 'pointer'}}>
                <SecureFlow>Secure Flow</SecureFlow>
                <Initiative>I N I T I A T I V E</Initiative>
            </TextContainer>
            <NavbarContainer>
                <Link to="/" style={{textDecoration: 'none'}}>
                    <NavItem selected={selectedNavItem === 'Intro'}>{formatMessage({ id: 'navbar.intro' })}</NavItem>
                </Link>
                <Link to="/services" style={{textDecoration: 'none'}}>
                    <NavItem selected={selectedNavItem === 'Services'}>{formatMessage({ id: 'navbar.services' })}</NavItem>
                </Link>
                <Link to="/team" style={{textDecoration: 'none'}}>
                    <NavItem selected={selectedNavItem === 'Team'}>{formatMessage({ id: 'navbar.team' })}</NavItem>
                </Link>
                <Link to="/#contact" style={{textDecoration: 'none'}}>
                    <NavItem selected={selectedNavItem === 'Contact'}>{formatMessage({ id: 'navbar.contact' })}</NavItem>
                </Link>
            </NavbarContainer>
            <Box>
                <Tooltip title={formatMessage({ id: 'navbar.international' })}>
                    <LanguageIcon
                        color={"primary"}
                        style={{
                            position: "fixed",
                            top: "64px",
                            right: "12px",
                            cursor: "pointer",
                            zIndex: 1
                        }}
                        onClick={toggleLocale}
                    />
                </Tooltip>
            </Box>
        </>
    );
};

export default Navbar;
