import NavbarMobile from "../components/NavbarMobile";
import {Background, ContentContainer} from "../css/HomeMobile.style";
import {
    AskForOneButton,
    ServiceCard,
    ServiceDescription,
    ServiceImage,
    ServiceTextContainer,
    ServiceTitle
} from "../css/ServicesMobile.style";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

// @ts-ignore
import devSecOps from "../../assets/DevSecOps.png";
// @ts-ignore
import penTest from "../../assets/PTest.png";
// @ts-ignore
import sast from "../../assets/Sast.png";
// @ts-ignore
import engineer from "../../assets/Engineering.png";
import FooterMobile from "../components/FooterMobile";
import {TeamContainer, TitleText} from "../css/TeamMobile.style";
import {OrangeText} from "../css/Home.style";
import {useIntl} from "react-intl";


const Services = () => {
    const { formatMessage } = useIntl();

    const servicesData = [
        {
            title: formatMessage({ id: 'services.security.title' }),
            description: formatMessage({ id: 'services.security.text' }),
            image: devSecOps,
        },
        {
            title: formatMessage({ id: 'services.development.title' }),
            description: formatMessage({ id: 'services.development.text' }),
            image: penTest,
        },
        {
            title: formatMessage({ id: 'services.experience.title' }),
            description: formatMessage({ id: 'services.experience.text' }),
            image: sast,
        },
        {
            title: formatMessage({ id: 'services.packaging.title' }),
            description: formatMessage({ id: 'services.packaging.text' }),
            image: engineer,
        },
    ];
    return (
        <Background>
            <NavbarMobile/>
            <ContentContainer style={{alignItems: "center"}}>
                <TitleText>SERVICES<OrangeText>.</OrangeText></TitleText>
                <TeamContainer style={{flexDirection: "column"}}>
                    {servicesData.map((service, index) => (
                        <ServiceCard key={index}>
                            <ServiceTitle>{service.title}<OrangeText>.</OrangeText></ServiceTitle>
                            <ServiceImage src={service.image} alt={service.title}/>
                            <ServiceTextContainer>
                                <ServiceDescription style={{color: "white"}}>{service.description}</ServiceDescription>
                                <AskForOneButton onClick={() => {
                                    window.location.replace("/#contact")
                                }}>Ask for one <ArrowForwardIcon
                                    style={{color: 'rgba(205, 126, 50, 1)'}}/></AskForOneButton>
                            </ServiceTextContainer>
                        </ServiceCard>
                    ))}
                </TeamContainer>
                <FooterMobile/>
            </ContentContainer>
        </Background>
    );
};

export default Services;
