import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import {IconButton} from "@mui/material";
import {
    ClickableText,
    CopyrightText,
    FooterContainer,
    HorizontalBar,
    LinksContainer,
    SocialIconsContainer
} from "../css/Footer.style";
import {useIntl} from "react-intl";


const Footer = () => {
    const { formatMessage } = useIntl();
    return (
        <FooterContainer>
            <LinksContainer>
                <ClickableText href="/services/">{formatMessage({ id: 'footer.text.services' })}</ClickableText>
                <ClickableText href="/#contact">{formatMessage({ id: 'footer.text.contact' })}</ClickableText>
                <ClickableText href="#">{formatMessage({ id: 'footer.text.cgv' })}</ClickableText>
            </LinksContainer>
            <HorizontalBar/>
            <SocialIconsContainer>
                <IconButton>
                    <InstagramIcon/>
                </IconButton>
                <IconButton>
                    <LinkedInIcon/>
                </IconButton>
                <IconButton>
                    <XIcon/>
                </IconButton>
            </SocialIconsContainer>
            <CopyrightText>
                &copy;Copyright <a href="#">Secure Flow Initiative</a> 2024
            </CopyrightText>
        </FooterContainer>
    );
};

export default Footer;
