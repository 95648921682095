import styled from 'styled-components';

export const TitleServices = styled.div`
  font-family: 'Roboto', sans-serif;
  margin: 20px auto;
  width: 445px;
  font-weight: 500;
  font-size: 64px;
  line-height: 75px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
`;

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 387px;
  width: 80%;
  left: 10%;
  right: 10%;
`;

export const Section = styled.div<{ reverse: boolean }>`
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  align-items: center;
`;

export const TextContainer = styled.div`
  width: 50%;
  margin: 0 5%;
`;

export const ImageContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  margin: 0 5%;
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 24px;
`;

export const Title = styled.h2`
  font-family: 'Cormorant Garamond', serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.75px;
  text-align: left;
`;

export const Description = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 100;
  line-height: 18.75px;
  text-align: left;
`;

export const AskForOne = styled.a`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  color: #fff; 
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: rgba(205, 126, 50, 1);
  }
`;