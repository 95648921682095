import React from "react";
import Navbar from '../components/Navbar';
import {Background, Content, HomeContainer, YellowText} from '../css/Home.style';
import Footer from "../components/Footer";
import {useNavigate} from "react-router-dom"
import {MemberCard, MemberImage, MemberInfo, MemberName, MemberRole, TeamContainer, TitleTeam} from "../css/Team.styles"
// @ts-ignore
import marius from "../../assets/marius.png"
// @ts-ignore
import killian from "../../assets/kylian.png"
// @ts-ignore
import mathis from "../../assets/mathis.png"
// @ts-ignore
import yani from "../../assets/yani.png"
// @ts-ignore
import enzo from "../../assets/enzo.png"
// @ts-ignore
import ilona from "../../assets/ilona.png"

// @ts-ignore
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import {faArrowLeftLong} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useIntl} from "react-intl";

const Team = () => {
    const navigate = useNavigate()
    const {formatMessage} = useIntl();

    const teamMembers = [
        {
            name: "Marius Delahay",
            role: formatMessage({id: 'team.marius.job'}),
            image: marius,
            linkedin: "https://www.linkedin.com/in/delahay-marius/",
        },
        {
            name: "Killian Mannarelli",
            role: formatMessage({id: 'team.killian.job'}),
            image: killian,
            linkedin: "https://www.linkedin.com/in/killian-mannarelli/",
        },
        {
            name: "Yani Madebos",
            role: formatMessage({id: 'team.yani.job'}),
            image: yani,
            linkedin: "https://www.linkedin.com/in/yani-madebos/",
        },
        {
            name: "Ilona Boizard",
            role: formatMessage({id: 'team.ilona.job'}),
            image: ilona,
            linkedin: "https://www.linkedin.com/in/ilona-boizard/",
        },
        {
            name: "Mathis Tamarelle",
            role: formatMessage({id: 'team.mathis.job'}),
            image: mathis,
            linkedin: "https://www.linkedin.com/in/mathis-tamarelle/",
        },
        {
            name: "Théophile Laborde",
            role: formatMessage({id: 'team.theophile.job'}),
            image: enzo,
            linkedin: "https://www.linkedin.com/in/enzomourany/",
        },
    ];
    const handleBackClick = () => {
        navigate('/');
    };
    const handleLinkedinLink = (url: string) => {
        window.open(url, "_blank");
    };

    return (
        <HomeContainer>
            <Background/>
            <Navbar selectedNavItem="Team"/>
            <Content style={{overflowX: "hidden"}}>
                <FontAwesomeIcon icon={faArrowLeftLong} style={{
                    fontSize: 60,
                    left: "15%",
                    position: "absolute",
                    top: "220px",
                    cursor: "pointer"
                }}
                                 onClick={handleBackClick}/>
                <TitleTeam>OUR TEAM<YellowText>.</YellowText></TitleTeam>
                <TeamContainer>
                    {teamMembers.map((member, index) => (
                        <MemberCard key={index}>
                            <MemberImage src={member.image} alt={member.name}/>
                            <MemberInfo>
                                <MemberName>{member.name}</MemberName>
                                <MemberRole>{member.role}</MemberRole>
                                <LinkedinIcon sx={{
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                    left: "85%",
                                    position: "relative"
                                }} onClick={() => handleLinkedinLink(member.linkedin)}/>
                            </MemberInfo>
                        </MemberCard>
                    ))}
                </TeamContainer>
                <div style={{top: "1462px", position: "absolute", width: "100%"}}>
                    <Footer/>
                </div>
            </Content>
        </HomeContainer>
    );
};

export default Team;
