import styled from 'styled-components'

export const ClickableText = styled.a`
  font-family: 'Cormorant Garamond', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.38px;
  text-align: left;
  color: #FFFFFF;
  margin-right: 10%;
  margin-left: 10%;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: rgba(218, 165, 32, 1);
  }
`;

export const FooterText = styled.footer`
  position: absolute;
  background-color: transparent;
  top: 48px;
  left: 48px;
`;

export const FooterContainer = styled.footer`
  background-color: transparent;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 80%;
  left:10%;
  right:10%;
  position: absolute;
`;

export const LinksContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  top: 50px;
`;

export const HorizontalBar = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 0.5px;
  background-color: rgba(218, 165, 32, 1);
  top: 108px;
  margin: 20px 0;
`;

export const SocialIconsContainer = styled.div`
  position: absolute;
  top: 156px;

  svg {
    margin: 0 10px;
    fill: #FFFFFF;
    width: 24px;
    height: 24px;
  }
`;

export const CopyrightText = styled.p`
  font-family: 'Cormorant Garamond', serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  position: absolute;
  top: 188px;

  a {
    color: rgba(205, 126, 50, 1);
    cursor: pointer;
    text-decoration: none;

  }
`;
