import React from 'react'
import Navbar from '../components/Navbar'
import { HomeContainer, Background, Content } from '../css/Home.style'

const Project = () => {
    return (
        <HomeContainer>
            <Background />
            <Navbar selectedNavItem="Projects" />
            <Content>
                <h1>Project</h1>
                <p>Details about the services offered...</p>
            </Content>
        </HomeContainer>
    );
};

export default Project;
