import styled from 'styled-components';

export const TitleTeam = styled.div`
  font-family: 'Roboto', sans-serif;
  position: absolute;
  top: 212px;
  left: 40%;
  width: 445px;
  font-weight: 500;
  font-size: 64px;
  line-height: 75px;
  color: rgba(255, 255, 255, 1);
  height: 100%;
`;

export const TeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding-top: 387px;
  width: 80%;
  left: 10%;
  position: absolute;
`;

export const MemberCard = styled.div`
  border-radius: 24px;
  overflow: hidden;
  width: 25%;
  padding: 20px;
  background: rgba(23, 23, 23, 1);
  color: rgba(233, 234, 236, 1);
  display: flex;
  flex-direction: column;
`;

export const MemberImage = styled.img`
  border-radius: 16px;
  width: 98%;
  height: 275px;
  object-fit: cover;
`;

export const MemberInfo = styled.div`
  flex-direction: column;
`;

export const MemberName = styled.h3`
  font-family: "Cormorant Garamond",serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.75px;
  text-align: left;
  margin-bottom: 8px;
  margin-top: 24px;
`;

export const MemberRole = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 15px;
  color: rgba(218, 165, 32, 1);
  margin-top: 0px;
`;
