import styled from 'styled-components';

export const TeamContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const MemberCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(23, 23, 23, 1);;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 90%;
  margin-top: 43px;
  padding: 20px;
`;

export const MemberImage = styled.img`
  width: 300px; 
  height: 300px; 
  object-fit: cover; 
  border-radius: 10%;
`;

export const MemberInfo = styled.div`
    margin-top: 10px;
`;

export const MemberName = styled.h2`
    font-family: "Cormorant Bold",sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: rgba(255, 255, 255, 1);
`;

export const MemberRole = styled.p`
    font-family: "Roboto Light",sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: rgba(218, 165, 32, 1);
;
`;
export const TitleText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 46.88px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 40px;
`;

