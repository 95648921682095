import React, {useEffect, useState} from 'react';
import NavbarMobile from "../components/NavbarMobile";
import {OrangeText, YellowText} from "../css/Home.style";
import {
    Background,
    CompactDescriptionText,
    CompactWhiteButton,
    ContentContainer,
    ContentContainerContact,
    DescriptionTextRight,
    Details,
    DivBackground,
    FormContact,
    FormContactForm,
    FormInput,
    FormText,
    FormTextarea,
    LayoutText,
    RomanNumber,
    SecureDreamsText,
    SendButton,
    TitleContact,
    TitleUpercase,
} from "../css/HomeMobile.style";
import axios from "axios";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import {Alert, IconButton, Typography} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import FooterMobile from "../components/FooterMobile";
import {useLocation} from "react-router-dom";
import {useIntl} from "react-intl";

const Home = () => {
    const loc = useLocation()
    const [formData, setFormData] = useState({name: '', email: '', message: ''});
    const [formStatus, setFormStatus] = useState('');
    const { formatMessage } = useIntl();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:3001/send-email', formData);
            setFormStatus(response.data.message);
        } catch (error) {
            setFormStatus('Failed to send message. Please try again.');
        }
    };

    useEffect(() => {
        const hash = loc.hash;
        if (hash === '#contact') {
            const contactSection = document.getElementById('contact');
            if (contactSection) {
                contactSection.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, [loc]);


    return (
        <Background>
            <NavbarMobile/>
            <ContentContainer>
                <SecureDreamsText>{formatMessage({ id: 'home.title.1' })}<YellowText>.</YellowText></SecureDreamsText>
                <CompactDescriptionText>
                    {formatMessage({ id: 'home.description.1' })} <OrangeText>{formatMessage({ id: 'home.description.2' })}</OrangeText> {formatMessage({ id: 'home.description.3' })}
                </CompactDescriptionText>
                <DivBackground>
                    <TitleUpercase>{formatMessage({ id: 'home.question' })}<YellowText>?</YellowText></TitleUpercase>
                    <DescriptionTextRight>
                        {formatMessage({ id: 'home.description.right.1' })}
                        <OrangeText> {formatMessage({ id: 'home.description.right.2' })}</OrangeText> {formatMessage({ id: 'home.description.right.3' })}<OrangeText>{formatMessage({ id: 'home.description.right.4' })}</OrangeText>.
                    </DescriptionTextRight>
                </DivBackground>
                <CompactWhiteButton onClick={() => {
                    window.location.replace("/#contact")
                }}>{formatMessage({ id: 'home.contact.button' })}<YellowText>!</YellowText></CompactWhiteButton>
                <LayoutText style={{textTransform: "uppercase"}}>
                    {formatMessage({ id: 'home.title.2' })} <YellowText>?</YellowText>
                </LayoutText>

                <RomanNumber>I</RomanNumber>
                <DivBackground>
                    <TitleUpercase style={{
                        textTransform: "uppercase"
                    }}>{formatMessage({ id: 'home.quality.title' })}<YellowText>.</YellowText></TitleUpercase>
                    <DescriptionTextRight>
                        {formatMessage({ id: 'home.quality.text.1' })}
                        <OrangeText>
                            {formatMessage({ id: 'home.quality.text.2' })}</OrangeText>..
                    </DescriptionTextRight>
                </DivBackground>
                <RomanNumber>II</RomanNumber>
                <DivBackground>
                    <TitleUpercase style={{
                        textTransform: "uppercase"
                    }}>{formatMessage({ id: 'home.expertise.title' })}<YellowText>.</YellowText></TitleUpercase>
                    <DescriptionTextRight>
                        {formatMessage({ id: 'home.expertise.text.1' })} <OrangeText>{formatMessage({ id: 'home.expertise.text.2' })}</OrangeText>
                        {formatMessage({ id: 'home.expertise.text.3' })} <OrangeText>{formatMessage({ id: 'home.expertise.text.4' })}</OrangeText>
                        {formatMessage({ id: 'home.expertise.text.5' })}<OrangeText>{formatMessage({ id: 'home.expertise.text.6' })}</OrangeText>.
                </DescriptionTextRight>
                </DivBackground>
                <RomanNumber>III</RomanNumber>
                <DivBackground>
                    <TitleUpercase style={{
                        textTransform: "uppercase"
                    }}>{formatMessage({ id: 'home.philosophy.title' })}<YellowText>.</YellowText></TitleUpercase>
                    <DescriptionTextRight>
                        {formatMessage({ id: 'home.philosophy.text.1' })} <OrangeText>{formatMessage({ id: 'home.philosophy.text.2' })}</OrangeText>{formatMessage({ id: 'home.philosophy.text.3' })}
                    </DescriptionTextRight>
                </DivBackground>
                <RomanNumber>IV</RomanNumber>
                <DivBackground>
                    <TitleUpercase style={{
                        textTransform: "uppercase"
                    }}>{formatMessage({ id: 'home.trust.title' })}<YellowText>.</YellowText></TitleUpercase>
                    <DescriptionTextRight>
                        {formatMessage({ id: 'home.trust.text.1' })} <OrangeText>{formatMessage({ id: 'home.trust.text.2' })} </OrangeText>
                        {formatMessage({ id: 'home.trust.text.3' })} <OrangeText>{formatMessage({ id: 'home.trust.text.4' })} </OrangeText>
                        {formatMessage({ id: 'home.trust.text.5' })}
                    </DescriptionTextRight>
                </DivBackground>
                <ContentContainerContact id={"contact"}>
                    <TitleContact>{formatMessage({ id: 'home.contact.title' })}<YellowText>.</YellowText></TitleContact>
                    <FormContact>
                        <FormContactForm onSubmit={handleSubmit}>
                            <FormText>{formatMessage({ id: 'home.contact.form.name' })}<OrangeText>*</OrangeText></FormText><br/>
                            <FormInput type="text" id="name" name="name" placeholder="Enter your name..."
                                       onChange={handleChange}/><br/>

                            <FormText>{formatMessage({ id: 'home.contact.form.email' })}<OrangeText>*</OrangeText></FormText><br/>
                            <FormInput type="text" id="email" name="email" placeholder="Enter your email address..."
                                       required onChange={handleChange}/><br/>

                            <FormText>{formatMessage({ id: 'home.contact.form.message' })}<OrangeText>*</OrangeText></FormText><br/>
                            <FormTextarea id="message" name="message" placeholder="Enter your message..."
                                          required onChange={handleChange}></FormTextarea><br/>

                            <SendButton type="submit">{formatMessage({ id: 'home.contact.form.button' })}</SendButton>
                            {formStatus === "Emails sent successfully" &&
                                <Alert sx={{top: "20px", position: "relative"}} severity="success">
                                    Email sent successfully
                                </Alert>
                            }
                            {formStatus === "Failed to send message. Please try again." &&
                                <Alert sx={{top: "20px", position: "relative"}} severity="error">
                                    {formStatus}
                                </Alert>
                            }
                        </FormContactForm>
                    </FormContact>
                    <Details>
                        <Typography>{formatMessage({ id: 'home.contact.text.1' })}<YellowText>.</YellowText></Typography>
                        <LocalPhoneIcon style={{
                            color: "rgba(218, 165, 32,1)",
                            top: "5px",
                            position: "relative",
                            marginRight: "3px"
                        }}/>07.
                        67. 46. 17. 31<br/>
                        <DriveFileRenameOutlineOutlinedIcon
                            style={{
                                color: "rgba(218, 165, 32,1)",
                                top: "5px",
                                position: "relative",
                                marginRight: "3px"
                            }}/>contact@secureflow.com<br/>
                    </Details>
                    <div style={{top: "32px", left: "5%", position: "relative"}}>
                        <IconButton>
                            <InstagramIcon sx={{color: "rgba(255, 255, 255, 1)"}}/>
                        </IconButton>
                        <IconButton>
                            <LinkedInIcon sx={{color: "rgba(255, 255, 255, 1)"}}/>
                        </IconButton>
                        <IconButton>
                            <XIcon sx={{color: "rgba(255, 255, 255, 1)"}}/>
                        </IconButton>
                    </div>
                </ContentContainerContact>
                <FooterMobile/>
            </ContentContainer>
        </Background>
    )
}

export default Home
