import {
    ClickableText,
    CopyrightText, FooterContainer,
    FooterText,
    HorizontalBar,
    LinksContainer,
    SocialIconsContainer
} from "../css/FooterMobile.style";
import {Initiative, SecureFlow} from "../css/Navbar.style";
import {IconButton} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import React from "react";
import {useIntl} from "react-intl";

const FooterMobile = ()=>{
    const { formatMessage } = useIntl();
    return (
        <FooterContainer>
            <FooterText>
                <SecureFlow style={{alignItems: "center", display:"flex", justifyContent:"center"}}>Secure Flow</SecureFlow>
                <Initiative>I N I T I A T I V E</Initiative>
            </FooterText>
            <LinksContainer>
                <ClickableText href="/services/">{formatMessage({ id: 'footer.text.services' })}</ClickableText>
                <ClickableText href="/#contact">{formatMessage({ id: 'footer.text.contact' })}</ClickableText>
                <ClickableText href="#">{formatMessage({ id: 'footer.text.cgv' })}</ClickableText>
            </LinksContainer>
            <HorizontalBar/>
            <SocialIconsContainer>
                <IconButton>
                    <InstagramIcon/>
                </IconButton>
                <IconButton>
                    <LinkedInIcon/>
                </IconButton>
                <IconButton>
                    <XIcon/>
                </IconButton>
            </SocialIconsContainer>
            <CopyrightText>
                &copy;Copyright <a href="#">Secure Flow Initiative</a> 2024
            </CopyrightText>
        </FooterContainer>
    )
}
export default FooterMobile;
